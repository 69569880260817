import React, { Fragment, useState } from "react";
import moment from "moment";
import ShareEvent from "./../../public/img/share-event.svg";
import {
	TwitterShareButton,
	FacebookShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
	EmailShareButton,
} from "react-share";
import { clickOutside } from "../helpers/utils";
import { useRef } from "react";
import Image from "next/future/image";
import Location from "../../public/Event/icons/location.png";
import Online from "../../public/Event/icons/online.png";
import Calendar from "../../public/Event/icons/calendar.png";
import Ticket from "../../public/Event/icons/ticket.png";

const EventCard = ({
	poster_url,
	name,
	startDate,
	endDate,
	dates,
    country,
	venue,
	city,
	is_free,
	modeType,
	event_url,
	starting_price,
	events_page,
}) => {
	const checkWebsite = () => {
		return event_url;
	};
	const start_date_start_Time = dates
		? moment(dates.start_date + " " + dates.start_time)
		: "";
	const end_date_end_time = dates
		? moment(dates.end_date + " " + dates.end_time)
		: "";

	const start_date = startDate || start_date_start_Time;
	const end_date = endDate || end_date_end_time;

	const dateFormatOne = {
		newStartDate: moment.utc(`${start_date}`).local().format("DD MMM, YYYY"),
		newEndDate: moment.utc(`${end_date}`).local().format("DD MMM, YYYY"),
	};
	const shareText = `I'm excited to join ${name} happening on ${moment(startDate,).format("MMMM Do, YYYY",)}, organized through KonfHub. Don't miss your chance to attend!\n`;

	const [shareClick, setShareClick] = useState(false);

	const toggleShareOptions = () => {
		if (shareClick === true) return `row share-options p-0 pb-3 px-1`;
		return `d-none`;
	};

	const wrapperRef = useRef("card");
	clickOutside(wrapperRef, () => {
		setShareClick(false);
	});

	return (
		<Fragment>
			<div ref={wrapperRef} className="card-placeholder h-100">
				<div
					onClick={() => window.open(event_url, "_blank")}
					className="card conference_card card_annimation h-100"
				>
					<div className="conference_banner">
						<div
							className="share-icon "
							onClick={(e) => {
								e.stopPropagation(), setShareClick((prev) => !prev);
							}}
						>
							<Image
								src={ShareEvent}
								alt="share event"
								width={25}
								height={25}
							/>
						</div>
						{shareClick && (
							<div className={toggleShareOptions()} 
                            onClick={(e) => {e.stopPropagation()}}
                            >
								<div className="col-12 pt-1 mb-2">
									<span>Share Via</span>
								</div>
								<div className="col-12 h-75 d-flex justify-content-between px-2">
									<EmailShareButton
										subject={`New Event - Konfhub Technologies`}
										body={shareText}
										url={checkWebsite()}
									>
										<Image
											className="shout-share"
											src="/img/mail-black.svg"
											width={22}
											height={22}
										/>
									</EmailShareButton>
									<WhatsappShareButton title={shareText} url={checkWebsite()}>
										<Image
											className="shout-share"
											src="/img/whatsapp-black.svg"
											width={22}
											height={22}
										/>
									</WhatsappShareButton>
									<FacebookShareButton quote={shareText} url={checkWebsite()}>
										<Image
											className="shout-share"
											src="/img/fb-black.svg"
											width={22}
											height={22}
										/>
									</FacebookShareButton>
									<TwitterShareButton title={shareText} url={checkWebsite()}>
										<Image
											className="shout-share"
											src="/img/event-card-twitter.svg"
											width={22}
											height={22}
										/>
									</TwitterShareButton>
									<LinkedinShareButton summary={shareText} url={checkWebsite()}>
										<Image
											className="shout-share"
											src="/img/lnkdin-black.svg"
											width={22}
											height={22}
										/>
									</LinkedinShareButton>
								</div>
							</div>
						)}
						<Image
							src={poster_url}
							className="card-img-top fit-cover"
							alt="Event Poster"
							width={290}
							height={145}
						/>
					</div>
					<div className="card_content">
						<div className="eventcard-body p-3">
							<span className="conference_name">{name}</span>

							<div className="row ">
								<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pt-xl-2 pt-lg-2 pt-2 pt-md-2 pt-sm-1">
									<span className="conference_details conference_details_back conference_city d-flex  align-items-center">
										{" "}
										<span
											className="icon-container"
											style={{ marginRight: modeType ? "5px" : "6px" }}
										>
											<img
												className="event-images"
												src={modeType ? Online : Location}
												alt={modeType ? "Online" : "Location"}
											/>
										</span>
										<span>
                                        {modeType ? "Online" : city ? city + (country ? `, ${country}`  : "") : "No city"}{" "}
										</span>
									</span>
								</div>

								<div className="col-12 pt-lg-2 pt-sm-2 pt-2">
									{dateFormatOne.newStartDate === dateFormatOne.newEndDate ? (
										<div className="conference_details conference_details_back">
											{" "}
											<img
												className="event-images"
												src={Calendar}
												alt="calander"
											/>{" "}
											{dateFormatOne.newStartDate}
										</div>
									) : (
										<div className="conference_details conference_details_back">
											{" "}
											<img className="event-images" src={Ticket} alt="ticket" />{" "}
											{dateFormatOne.newStartDate}
											{" - "}
											{dateFormatOne.newEndDate}
										</div>
									)}
								</div>
								<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pt-xl-2 pt-lg-2 pt-2 pt-md-2 pt-sm-1">
									<span className="conference_details conference_details_back conference_city">
										{" "}
										<i className="fas fa-ticket-alt" />{" "}
										{events_page === true &&
										starting_price !== null &&
										starting_price !== undefined &&
										starting_price !== "" ? (
											is_free ||
											starting_price === "" ||
											!starting_price ||
											starting_price === undefined ? (
												"Free"
											) : (
												<>
													Starts at{" "}
													<span className="red">₹{starting_price}</span>
												</>
											)
										) : is_free ? (
											"Free"
										) : (
											"Paid"
										)}{" "}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<style jsx>
				{`
                .conference_card {
                    height: 315px !important;
                    width: 290px;
                    border-radius: 6px;
                    background-color: white;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                }
                .shout-fb, .shout-twtr, .shout-share{
                    height: 22px;
                    width: 22px;
                    cursor:pointer;
                    filter: invert(0);
                }
                .shout-share-active {
                    filter: invert(1);
                }
                .shout-share-btn{
                    height: 17px;
                    width: 17px;
                    cursor:pointer;
                    filter: invert(1);

                }
                .event-images{
                    width:14px;
                }
                .icon-container{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }
                .eventcard-body{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
                .shout-share-btn:hover {
                    filter: none;
                    transition: invert .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .shout-fb:hover, .shout-twtr:hover, .shout-share:hover{
                    filter: invert(40%) sepia(40%) saturate(1052%) hue-rotate(316deg) brightness(110%) contrast(98%);
                    transition: invert .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                    .share-icon{
                        position: absolute;
                        bottom: 85px;
                        right: 15px;
                        cursor: pointer;
                    }
                    .red{
                        color: red !important;
                    }
                    
                    .fit-cover {
                        object-fit: cover;
                    }

                    .share-options{
                        position: absolute;
                        left: 100px;
                        bottom: 0px;
                        width: 180px;
                        background: #fff;
                        border-radius: 10px;
                        box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
                        z-index: 4;
                    }
                    .share-options span{
                    font-size: 12px;
                }  
                .share-options-overlay {
                    color: white;
                    position: fixed; /* Sit on top of the page content */
                    width: 100%; /* Full width (cover the whole page) */
                    height: 100%; /* Full height (cover the whole page) */
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 3;
                  }

                    .card_content {
                        position: absolute;
                        top: auto;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #ffffff;
                        -webkit-transform: translateY(48%);
                        transform: translateY(48%);
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                        -webkit-transition: -webkit-transform 0.4s;
                        transition: -webkit-transform 0.4s;
                        transition: transform 0.4s;
                        transition: transform 0.4s -webkit-transform 0.4s;
                    }


                    .conference_banner {
                        position: relative;
                        height: 70%;
                    }

                    .conference_logo {
                        position: absolute;
                        top: 6%;
                        left: 6%;
                        height: 48px;
                        width: 48px;
                        border: 1px solid #ffffff;
                        box-sizing: border-box;
                        border-radius: 5px;
                    }

                    .conference_dates {
                        position: absolute;
                        top: 6%;
                        right: 6%;
                        font-family: Space Grotesk;
                        font-weight: bold;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding: 10px;
                        text-align: center;
                        background-color: #ffffff;
                    }

                    .conference_category {
                        font-family: Space Grotesk;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        display: block;
                    }

                    .conference_name {
                        font-family: Nunito;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0em;
                        height: 39px;
                        text-align: left;
                        color: #141B25;
                        width: 280px;
                        width: 95% !important;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .conference_details {
                        font-family: Prompt;
                        font-size: 10.8543px;
                        line-height: 16px;
                        color: #141b25;
                        display: block;
                    }

                    .conference_details_back {
                        font-family: Nunito;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #141b25;
                    }
                   
                    
                    .endDate{
                        margin-left: 14px; 
                    }
                    .conference_city{
                        font-family: Prompt;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 18px;
                        width: 93% !important;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #141B25;
                        width: 275px;
                    }
                    .conference_city span{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .endDate{
                        margin-left: 14px; 
                    }
                    .conference_arrow {
                        text-align: right;
                        color: #fb5850;
                        display: block;
                        margin-top: 30px
                    }
                    .arrow_container {
                        border: 1px solid #fb5850;
                        padding: 5px 10px 5px 10px;
                        border-radius: 50%;
                        cursor: pointer;

                    }
                    a{
                        all: unset;
                    }
                    
                `}
			</style>
		</Fragment>
	);
};

export default EventCard;
